<template>
<div>
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

  <div class="content">
    <validation-observer ref="VFormLaporanPersalinan">
      <b-form @submit.prevent="doSubmit()">
        <div class="card">
          <div class="card-header bg-white">
            <div class="row align-items-center">
              <div class="col-md-auto">
                <div class="row align-items-center">
                  <div class="col-md-auto">
                    <h5 class="card-title font-weight-semibold">Form Laporan Persalinan</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 table-responsive">
                <table class="table table-sm  table-bordered" style="width: 2000px;">
                  <tbody>
                    <tr>
                      <th class="bg-primary">Tanggal & Jam </th>
                      <th class="bg-primary">Tensi </th>
                      <th class="bg-primary" width="320">Nadi </th>
                      <th class="bg-primary" width="170">Suhu </th>
                      <th class="bg-primary">HIS </th>
                      <th class="bg-primary">DJJ </th>
                      <th class="bg-primary">VT </th>
                      <th class="bg-primary">Keterangan </th>
                      <th class="bg-primary">Aksi </th>
                    </tr>
                    <tr v-for="(v, k) in dataLaporan" :key="`laporan-${k}`">
                      <td>
                        <date-picker v-model="v.date" :default-value="v.date" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="-- Tanggal --" @input="updateRow(v)"></date-picker>                        
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="v.sistolik" :formatter="Formatter.number" @change="updateRow(v)" />
                          <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                          <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="v.diastolik" :formatter="Formatter.number" @change="updateRow(v)" />
                          <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                        </div>

                        <VValidate
                          name="Tekanan Darah"
                          :value="v.sistolik && v.diastolik"
                          :rules="{ required: 1 }"
                        />
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input type="text" class="form-control" v-model="v.nadi" :formatter="Formatter.number" @change="updateRow(v)" />

                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          <div class="input-group-append">
                            <v-select 
                              :options="MonitoringOptions.mrNadi" 
                              :reduce="(v) => v.value" 
                              label="label" placeholder="-- Pilih --" 
                              style="min-width: 104px;" 
                              v-model="v.nadi_type"
                              @input="updateRow(v)"
                            />
                          </div>
                        </div>

                        <VValidate
                          name="Nadi"
                          :value="v.nadi && v.nadi_type"
                          :rules="{ required: 1 }"
                        />
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input type="text" v-model="v.suhu" :formatter="Formatter.decimalNumber" maxlength="3" @change="updateRow(v)" />
                          <div class="input-group-append"><span class="input-group-text">C</span>
                          </div>
                        </div>

                        <VValidate 
                          name="Suhu"
                          :vid="`suhu-${k}`"
                          v-model="v.suhu"
                          :rules="{ required: 1 }"
                        />
                      </td>

                      <td>
                        <b-form-input type="text" v-model="v.his" @change="updateRow(v)" />
                      </td>

                      <td>
                        <b-form-input type="text" v-model="v.djj" @change="updateRow(v)" />
                      </td>

                      <td>
                        <b-form-textarea rows="4" type="text" v-model="v.vt" @change="updateRow(v)" />
                      </td>
                      
                      <td>
                        <b-form-input type="text" v-model="v.keterangan" @change="updateRow(v)" />
                      </td>

                      <td>
                        <a href="javascript:;" @click="deleteRow(v, k)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800" v-b-tooltip.hover data-popup="tooltip" title="Hapus" v-if="dataLaporan.length > 1"><i class="icon-trash"></i></a>
                      </td>
                    </tr>

                  </tbody>

                  <tfoot>
                    <tr>
                      <td colspan="7" class="text-center">
                        <a href="javascript:;" @click="addLaporan()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" class="btn" @click="$router.back()">Kembali</button>
              <button type="button" class="btn btn-outline-primary mr-2" @click="$router.go(-1)">Simpan Sementara</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'

import Gen from '@/libs/Gen'

import GlobalVue from '@/libs/Global'

export default {
  components: {
    PatientInfo, DatePicker
  },  

  extends: GlobalVue,

  data() {
    return {
      loading: {
        patientInfo: false
      },

      patientData: {},

      mrCriteria: [],
      mrDischargePlanning: [],
      dataLaporan: [{}],
      mrValidation: {},

      row: {},

      formData: {
        discharge_planning: [],
        is_discharge: false
      },
    }
  },

  mounted() {
    this.getPatientInfo()
    this.getDataForm()
  },

  methods: {
    deleteRow(data, key){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: "delete-laporan-persalinan",
            id_registrasi: this.$route.params.pageSlug, 
            id: data.id
          }
        },
        "POST"
      ).then(() => {
        this.dataLaporan.splice(key, 1)
      })
    },
    addLaporan(){
      this.dataLaporan.push({ vt: 'pembukaan .... cm, efismen ....cm, KK +/-, hodge 1-4, LD+/-' })
      this.updateRow(this.dataLaporan[this.dataLaporan.length - 1])
    },

    updateRow(data){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: !data.id ? "add-laporan-persalinan" : "update-laporan-persalinan",
            id_registrasi: this.$route.params.pageSlug,            
            ...data
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this.loading, 'patientInfo', false)
        if(!data.id){
          this.$set(data, 'id', resp.data.id)
        }
      })
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getDataForm() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(resp => {
        const {
          dataLaporan,
          mrValidation
        } = resp.data

        this.$set(this, 'dataLaporan', dataLaporan)
        this.$set(this, 'mrValidation', mrValidation)
      })
    },

    doSubmit() {
      this.$refs['VFormLaporanPersalinan'].validate().then(success => {
        if (!success) return false
        this.$swal.fire({
          title: 'Loading...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        setTimeout(() => { 
          this.$swal.close()

          this.$swal({
            title: "Berhasil mengisi laporan persalinan",
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(result => {
            if (result.value) {
              this.$router.back()
            }
          })
        }, 700)
      })
    }
  },

  watch: {
    $route() {
      this.getPatientInfo()
      this.getDataForm()
    },
  },

  computed: {
    Formatter(){
      return Formatter
    },

    MonitoringOptions(){
      return MonitoringOptions
    }
  }
}
</script>
